/* VideoBackground.css */
.video-container {
  position: relative;
  width: 100%;
  height: 90vh; /* Adjust the height as needed */
  overflow: hidden;
}

video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.text-overlay {
  position: absolute;
  top: 1%;
  left: 1%;
  /* transform: translate(-50%, -50%); */
  color: #fff; /* Adjust text color as needed */
  text-align: center;
  z-index: 1; /* Ensure the text appears above the video */
  background-color: rgba(0, 0, 0, 0.3);
  padding: 15px;
  border-radius: 15px;
}

.text-overlay h1 {
  font-size: 4rem; /* Adjust header text size as needed */
  margin-bottom: 10px;
  font-weight: bold; /* Make the header text bold */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6); /* Add a subtle text shadow */
}

.text-overlay p {
  font-size: 2rem; /* Adjust paragraph text size as needed */
  font-weight: bold; /* Make the paragraph text bold */
  color: #3ad7a6;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6); /* Add a subtle text shadow */
}

.text-overlay-type {
  position: absolute;
  bottom: 0%;
  right: 0%;
  width: 100%;
  /* transform: translate(-50%, -50%); */
  color: #fff; /* Adjust text color as needed */
  text-align: center;
  z-index: 1; /* Ensure the text appears above the video */
  background-color: rgba(0, 0, 0, 0.5);
  padding: 15px;
  border-radius: 15px;
  font-size: 2rem;
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: orange;
  }
}

@media only screen and (max-width: 991px) {
  .video-container {
    height: 80vh; /* Adjust the height as needed */
  }
  .text-overlay {
    padding: 0px;
    /* top: 50%;
    left: 10%; */
    /* width: 80%; */
    /* transform: translateY(-50%); */
  }
  .text-overlay h1 {
    font-size: 3rem;
  }

  .text-overlay p {
    font-size: 1.5rem;
  }

  .text-overlay-type {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 400px) {
  .text-overlay h1 {
    font-size: 1.5rem;
  }

  .text-overlay p {
    font-size: 0.75rem;
  }

  .text-overlay p {
    font-size: 0.75rem;
  }

  .text-overlay-type {
    font-size: 1rem;
  }
}
