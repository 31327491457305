.PartnersSwiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.PartnersSwiper .swiper-container {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.PartnersSwiper .swiper-slide {
  background-position: center;
  background-size: cover;
  width: 200px;
  height: 200px;
}

.PartnersSwiper .swiper-slide .imgBx {
  position: relative;
  width: 150px;
  height: 150px;
  background: #fff;

  box-shadow: 3px 3px 20px black;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
}

.PartnersSwiper .swiper-slide .imgBx img {
  position: absolute;
  top: 0;
  left: 0;
  width: 80%;
  height: 80%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: contain;
}

@media only screen and (max-width: 991px) {
  .PartnersSwiper .swiper-slide {
    width: 150px;
    height: 150px;
  }

  .PartnersSwiper .swiper-slide .imgBx {
    width: 120px;
    height: 120px;
  }

  /* .PartnersSwiper .swiper-slide .imgBx img {
    width: 80%;
    height: 80%;
  } */
}

@media only screen and (max-width: 500px) {
  .PartnersSwiper .swiper-slide {
    width: 100px;
    height: 100px;
  }

  .PartnersSwiper .swiper-slide .imgBx {
    width: 80px;
    height: 80px;
  }

  /* .PartnersSwiper .swiper-slide .imgBx img {
    width: 80%;
    height: 80%;
    top: 50%;
  } */
}
