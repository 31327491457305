.sectionContainer {
  background-color: #fefefe;
  /* background-image: linear-gradient(315deg, #fefefe 0%, #00a4e4 74%); */
  text-align: center;
  padding: 20px 20px;
}

.cardSectionContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
  position: relative;
  padding: 50px;
}

.cardItem {
  background-color: #e6e6e6;
  background-image: linear-gradient(180deg, #e6e6e6 0%, #fffff0 30%);
  width: 25%;
  height: 370px;
  color: black;
  text-align: center;
  box-shadow: 5px 5px 10px #000000;
  border-radius: 15px 15px 0px 0px;
  margin: 15px !important;
}

.cardItem img {
  object-fit: cover;
  border-radius: 15px 15px 0px 0px;
  width: 100%;
}

.cardTextTiltle {
  color: #603495;
  font-weight: bold;
  font-size: 1.25rem;
}
.CardTextContent {
  color: black;
  font-size: 0.8rem;
}

.cardButton {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  color: white !important;
}

.zoom {
  transition: transform 0.2s; /* Animation */
  margin: 0 auto;
}

.zoom:hover {
  transform: scale(1.1);
  filter: drop-shadow(-8px -8px 15px white);
}

@media only screen and (max-width: 800px) {
  .cardSectionContainer {
    flex-direction: column;
    width: 100%;
    padding: 10px;
  }

  .cardItem {
    width: 90%;
    height: 450px;
    margin: 10px !important;
  }
  .cardItem img {
    object-fit: cover;
    border-radius: 15px 15px 0px 0px;
    width: 100%;
    height: 250px;
  }
  .CardTextContent {
    font-size: 1rem;
  }
}
@media only screen and (max-width: 400px) {
  .cardItem {
    height: 350px;
  }
  .cardItem img {
    object-fit: cover;
    border-radius: 15px 15px 0px 0px;
    width: 100%;
    height: 150px;
  }
  .cardTextTiltle {
    font-size: 1rem;
  }
  .CardTextContent {
    font-size: 0.75rem;
  }
}
