/*nav bar*/
.hadoraba-navbar {
  display: flex;
  flex-wrap: nowrap;
  background-color: rgb(175, 235, 243, 0.5);
  background-image: linear-gradient(180deg, #afebf3 0%, #fff9c4 100%);

  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
}

.store-nav-link {
  background-image: linear-gradient(to top, #ff0844 0%, #ffb199 100%);
  background-color: "#ff0844";
  border-radius: 15px;
  border: 3px solid #0ccbff;
  padding: 5px 10px;
  color: black;
  font-family: "David", Arial, Helvetica, sans-serif;
}

.student-nav-link {
  background-image: linear-gradient(to top, #ff0844 0%, #ffb199 100%);
  background-color: "#ff0844";
  border-radius: 5px;
  padding: 5px 10px;
  color: black;
  font-family: "David", Arial, Helvetica, sans-serif;
}

.cookie {
  align-items: baseline;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  left: 0px;
  position: fixed;
  width: 100%;
  z-index: 999;
  bottom: 0px;
  background: #22a8eb;
  text-align: center;
  color: black;
}

.cookie-text {
  margin: 15px;
  text-shadow: 1px 1px 5px #2ecc71;
}

.cookie-button {
  background: #2ecc71;
  color: black;
  border-radius: 10px;
  border: black 2px solid;
  padding: 10px;
  margin: 15px 10px;
}

.cookie-button:hover {
  background-color: #ff5d94;
  animation: headShake;
  animation-duration: 1s;
}

.hadoraba-navbar.inSlide {
  background-color: white;
  /* background-image: linear-gradient(315deg, #2d3436 0%, #000000 74%); */
  box-shadow: 0px 5px 15px rgba(255, 255, 255, 0.8);
  border-radius: 0 0 15px 15px;
  transition: 0.5s;
}

.logo {
  margin-right: 20px;
}
.logo img {
  width: 200px;
  height: 77px;
}

.logo img:hover {
  animation: bounce 2s;
}

nav {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  padding: 10px;
  font-size: 18px;
  justify-content: center;
  align-content: center;
  width: 80%;
}

nav a {
  margin-right: 20px;
  color: black;
  font-weight: 500;
  position: relative;

  padding: 2px 5px;
}

nav a:hover,
.current-menu-item {
  color: #22a8eb;
  transition: 0.3s;
}

.user-buttons {
  display: flex;
  padding: 20px;
  margin-right: auto;
}

/*Drop menu*/
.action .menu-header {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.action .menu {
  position: absolute;
  top: 50px;
  left: -80px;
  padding: 10px 20px;
  background: #fff;
  width: 300px;
  box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.7);
  border-radius: 15px;
  transition: 0.5s;
  z-index: 100;
  visibility: hidden;
  opacity: 0;
  transition-delay: 0.2s;
}

.action .menu.active {
  top: 30px;
  visibility: visible;
  opacity: 1;
}

.action .menu::before {
  content: "";
  position: absolute;
  top: -5px;
  right: 145px;
  width: 20px;
  height: 20px;
  background-color: #fff;
  transform: rotate(45deg);
}

.action .menu ul li {
  list-style: none;
  padding: 10px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
}

.action .menu ul li img {
  max-width: 30px;
  margin-left: 5px;
  margin-right: 5px;
  opacity: 1;
  transition: 0.5s;
}

.action .menu ul li a {
  display: inline-block;
  text-decoration: none;
  text-align: right;
  font-size: 16px;
  color: #555;
  font-weight: 500;
  transition: 0.5s;
}

.action .menu ul li:hover a {
  color: #ff5d94;
}

/*USER*/

.user {
  margin-left: 20px;
  margin-top: 10px;
}

.user .user-header {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
}
.user .user-header img {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50px;
}

.user .user-menu {
  position: absolute;
  top: 80px;
  left: 20px;
  padding: 10px 20px;
  background: #fff;
  width: 100px;
  box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.7);
  border-radius: 15px;
  transition: 0.5s;
  z-index: 100;
  visibility: hidden;
  opacity: 0;
  transition-delay: 0.2s;
}

.user .user-menu.active {
  top: 50px;
  visibility: visible;
  opacity: 1;
}

.user .user-menu::before {
  content: "";
  position: absolute;
  top: -5px;
  left: 10px;
  z-index: -1;
  width: 20px;
  height: 20px;
  background-color: #fff;
  transform: rotate(45deg);
}

.user .user-menu ul li {
  list-style: none;
  padding: 10px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
}

.user .user-menu ul li img {
  max-width: 20px;
  margin-left: 2px;
  margin-right: 2px;
  opacity: 1;
  transition: 0.5s;
}

.user .user-menu ul li a {
  display: inline-block;
  text-decoration: none;
  text-align: right;
  font-size: 16px;
  color: #555;
  font-weight: 500;
  transition: 0.5s;
}

.user .user-menu ul li:hover a {
  color: #ff5d94;
}

/*****Mobile*******/

.mobile-menu {
  display: flex;
  order: -1;
}

.mobile-menu-header {
  border-radius: 5px;
  background: #22a8eb;
  width: 48px;
  height: 48px;
  margin-right: 20px;
  margin-top: 10px;
}

.mobile-menu .mobile-menu-list {
  position: absolute;
  height: calc(100vh - 65px);
  top: 65px;
  right: -100%; /*change back to -100%*/
  padding: 10px 20px;
  background: #fff;
  width: 100%;
  box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.7);
  border-radius: 15px;
  transition: 0.5s;
  visibility: hidden;
  overflow: auto;
  opacity: 0;
  transition-delay: 0.2s;
}

.mobile-menu .mobile-menu-list.active {
  top: 65px;
  right: 0;
  visibility: visible;
  opacity: 1;
}

.mobile-menu .mobile-menu-list a {
  list-style: none;
  padding: 10px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile-menu .mobile-menu-list a {
  text-decoration: none;
  color: #555;
  font-weight: 500;
  transition: 0.5s;
}

.mobile-menu .mobile-menu-list a:hover,
.mobile-menu .mobile-menu-list .current-menu-item {
  color: #ff5d94;
}

/*dropdown*/

.arrow-icon {
  color: #22a8eb;
  margin-right: 10px;
  transition: 0.2s;
}

.arrow-icon.open {
  transform: rotate(180deg);
  transition: 0.2s;
}

.dropdown {
  display: none;
  transition: 0.2s;
  opacity: 0;
}

.dropdown.active {
  display: block;
  transition: 0.2s;
  opacity: 1;
}

.dropdown-header {
  font-size: 16px;
  transition: 0.5s;
}

@media screen and (max-width: 800px) {
  .logo img {
    margin-top: 10px;
    width: 100px;
    height: 38px;
  }
}

@media screen and (max-width: 300px) {
  .hadoraba-navbar {
    position: fixed;
  }
  .logo {
    display: none;
  }

  .mobile-menu-header {
    margin-right: 2px;
    margin-top: 10px;
  }
  .user .user-header {
    position: relative;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
  }
  .user {
    margin-left: 20px;
    margin-top: 20px;
  }
  .user-buttons {
    display: flex;
    padding: 0;
    margin-right: 5px;
  }
}
