.playground-container {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  padding-top: 20px;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.game-container-new {
  margin: 5px;
  padding: 5px;
  background-color: #f70174;
  background-image: linear-gradient(315deg, #f70174 0%, #22a8eb 100%);

  border: black 3px solid;
  border-radius: 70px 30px 50px 30px;
  direction: rtl;
  position: relative;
  text-align: center;
  color: white;
}

.top-right {
  position: absolute;
  text-shadow: 2px 2px rgb(28, 120, 161);
  background-color: rgba(0, 0, 0, 0.39);
  color: white;
  border-radius: 15px;
  padding: 3px;
  top: 8px;
  right: 16px;
}

.student-container-new {
  display: inline-block;
  margin: 1px;
}

.zoom {
  transition: transform 0.2s; /* Animation */
  margin: 0 auto;
}

.game-container-new:hover .zoom {
  transform: scale(1.1);
  filter: drop-shadow(-8px -8px 15px white);
  color: rgb(28, 120, 161);
}

.title-container {
  margin: 0 20%;
  padding: 5px 0;
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.2);
  text-align: center;
}
.rainbowStyle {
  background-image: linear-gradient(
    to right,
    violet,
    indigo,
    blue,
    green,
    yellow,
    orange,
    red
  );

  padding-top: "20px";
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.instruction-container {
  width: 70%;
  margin: 20px auto;
  padding: 20px 25px;
  background: #ee7752;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: black;
}

.instruction-container h2 {
  font-weight: 900;
  text-shadow: 1px 1px 5px #fff;
}

.instruction-container .instruction-details {
  font-size: 1.2rem;
  line-height: 1.5rem;
  white-space: pre-line;
}

@media screen and (max-width: 900px) {
  .title-container {
    margin: 0 10%;
  }

  .rainbowStyle {
    font-size: 1.5rem;
  }
}
