.shopContainerButton {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 20px 5px 10px 5px;
}

.shopButton {
  font-size: 1.5em;
  border-radius: 20px;
  border: #00fffc 2px solid;
  background-color: #ea0086;
  color: white;
}

.store-cover {
  /* margin-top: 100px; */
  position: relative;
  display: inline-block;
  width: 100%;
}

.store-image-cover {
  background: url("../../../assets/matrix-background.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
  height: 450px;
  /* margin-top: px; */
  width: 100%;
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
}

.book-example {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.book-example img {
  object-fit: contain;
  width: 80%;
}
.shopingil-gif {
  width: 50%;
}
.shopingil-gif img {
  width: 100%;
}
.scratch-hacker {
  margin-top: 100px;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.scratch-hacker img {
  width: 50%;
  object-fit: contain;
}
/* 
.store-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.store-item {
  background-image: linear-gradient(to top, #e14fad 0%, #f9d423 100%);
  margin: 20px 10px;
  border-radius: 5px;

  width: 300px;
  height: 600px;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.store-item img {
  width: 300px;
  height: auto;
  border-radius: 5px 5px 0px 0px;
}
.store-item h2 {
  margin-top: 5px;
  color: blue;
}

.store-item h3 {
  color: black;
  margin: auto;
}

.store-item button {
  border-radius: 10px;
  padding: 10px;
  font-size: 1rem;
  margin: 5px;
}

.store-btn span {
  display: inline-flex;
  align-items: center;
  text-align: center;
  margin: auto;
} */

.rowShop {
  margin: 20px 20px;
  padding: 20px 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
}

.imageShop {
  width: 30%;
  display: flex;

  justify-content: center;
  align-content: center;
}
.imageShop img {
  width: 80%;
  object-fit: contain;
}
.contentShop {
  margin: 20px auto;
  padding: 20px 50px;
  width: 90%;
  font-size: 1.1rem;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: 2px 2px 5px #fff;
  line-height: 1.2;
}

.shop-buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
}

.storeContainerButton {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  margin: 20px 5px 10px 5px;
  text-align: center;
  width: 40%;
}

.shopButton {
  font-size: 1.5em;
  border-radius: 20px;
  border: #00fffc 2px solid;
  background-color: #ea0086;
  text-align: center;
  color: white;
  width: 100%;
}

.readDemoButton {
  font-size: 1.5em;
  border-radius: 20px;
  border: #ea0086 2px solid;
  background-color: #00fffc;
  color: black;
  text-align: center;
  width: 100%;
}

@media only screen and (max-width: 800px) {
  .store-image-cover {
    height: 850px;
    flex-direction: column;
  }

  .book-example {
    width: 100%;
  }
  .book-example img {
    object-fit: contain;
    width: 50%;
  }

  .google-shopingil {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .google-shopingil img {
    width: 100%;
  }

  .scratch-hacker {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .scratch-hacker img {
    width: 20%;
    object-fit: contain;
  }

  .contentShop {
    margin-top: 20px;
  }

  .shopButton {
    font-size: 1.2em;
  }

  .readDemoButton {
    font-size: 1.2em;
  }
}

@media only screen and (max-width: 600px) {
  .store-image-cover {
    height: 800px;
    flex-direction: column;
  }

  .book-example {
    width: 100%;
  }
  .book-example img {
    object-fit: contain;
    width: 80%;
  }

  .scratch-hacker {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .scratch-hacker img {
    width: 30%;
    object-fit: contain;
  }
  .contentShop {
    margin-top: 20px;
  }

  .shopButton {
    font-size: 1em;
  }

  .readDemoButton {
    font-size: 1em;
  }
  .shop-buttons {
    flex-direction: column;
  }

  .storeContainerButton {
    width: 90%;
  }
}

@media only screen and (max-width: 400px) {
  .store-image-cover {
    height: 800px;
    flex-direction: column;
  }
}

@media only screen and (min-width: 1081px) {
  .store-image-cover {
    height: 500px;
    flex-direction: row;
  }

  .book-example {
    width: 100%;
  }
  .book-example img {
    object-fit: contain;
    width: 50%;
  }

  .scratch-hacker {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .scratch-hacker img {
    width: 50%;
    object-fit: contain;
  }
}
