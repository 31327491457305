.activitiesContainer {
  background-color: white;
  /* background-image: linear-gradient(180deg, #000000 0%, #04619f 74%); */
  color: #603495;
  text-align: center;
  margin-top: 20px;
}
.headerSubtitlePar {
  font-size: 2rem;
  padding: 20px 50px 20px 50px;
  background-color: white;
  margin-right: 20px;
  margin-left: 20px;
  margin-bottom: 20px;
  border-radius: 15px;
  box-shadow: 5px 5px 15px white;
}


.GoodToKnow {
  background-color: white;
  margin-right: 20px;
  margin-left: 20px;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 5px 5px 15px white;
}
.GoodToKnow h4 {
  text-align: right;
}

.GoodToKnow p {
  text-align: right;
  line-height: 25px;
}

.btnCallToAction {
  background: #603495;
  color: #fff;
  border: 1px solid black;
  font-size: 1.4em;
  /* max-width: 120px; */
  font-weight: 500;
  padding: 15px 0px;
  cursor: pointer;
  width: 40%;
  border-radius: 15px;
}

.btnCallToAction:hover {
  animation: headShake 1s;
  background: #6a4498;
  color: #fff;
}


.btnCallToActionOnline {
  background: #603495;
  color: #fff;
  border: 1px solid black;
  font-size: 1.4em;
  /* max-width: 120px; */
  font-weight: 500;
  padding: 15px 0px;
  cursor: pointer;
  width: 40%;
  border-radius: 15px;
  box-shadow: 2px 2px 5px 3px #3ad7a6 ;
}

.btnCallToActionOnline:hover {
  background: #6a4498;
  color: #fff;
}

@media only screen and (max-width: 600px) {
  .btnCallToActionOnline {
    width: 80%;
    font-size: 1.2em;

  }
}

@media only screen and (max-width: 400px) {
  .btnCallToActionOnline {
    width: 80%;
    font-size: 1em;

  }
 
  .btnCallToAction {
    width: 60%;
    font-size: 1em;
  }
}
