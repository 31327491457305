.course-container {
  background-color: #ffffff;
  /* background-image: linear-gradient(315deg, #ffffff 0%, #91a6ff 74%); */
  color: black;
  text-align: center;
}

.shortcutButtons {
  margin: 15px;
}

.shortcutButtons button {
  margin: 15px;
}

.courseVideoContainer {
  margin-top: 20px;
  margin-bottom: 20px;
}
.courseContentContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}

.blockContent {
  margin: 5px;
  padding: 15px;
  text-align: right;
  width: 80%;
  background-color: #e6e6e6;
  background-image: linear-gradient(315deg, #e6e6e6 0%, #fffff0 35%);
  box-shadow: 5px 5px 5px gray;
  position: relative;
}

.blockContent h1 {
  text-align: center;
}

@media only screen and (max-width: 800px) {
  .courseContentContainer {
    flex-direction: column;
  }

  .blockContent {
    width: 95%;
    align-self: center;
  }
}
