.contact-container {
  /* background: linear-gradient(
    90deg,
    #0e3959 0%,
    #0e3959 30%,
    #923cb5 30%,
    #923cb5 100%
  ); */
  background-color: white;
  text-align: right;
}

.contact-us {
  position: relative;
  width: 100%;
  padding: 40px 100px;
}

.contact-us .contact-title h2 {
  display: flex;
  justify-content: center;
  align-items: center;
  text-shadow: 1px 1px 2px #000;
  font-size: 3rem;
}

.contact-us .contact-title h2 {
  color: black;
  font-weight: 500;
}

.contact-form {
  grid-area: form;
}

.contact-info {
  grid-area: info;
}

.contact-map {
  grid-area: map;
}

.contact-card {
  padding: 40px;
  background: #fff;
  box-shadow: 0 5px 35px rgba(0, 0, 0, 0.15);
}

.contact-box {
  position: relative;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 5fr 4fr;
  grid-template-areas: "form info" "form map";
  gap: 20px;
  margin-top: 20px;
}

.contact-us-title {
  color: #603495;
  font-weight: 500;
  font-size: 1.4em;
  margin-bottom: 10px;
}

/*form*/

.formBox {
  position: relative;
  width: 100%;
}

.formBox .row50 {
  display: flex;
  gap: 20px;
}

.inputBox {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  width: 50%;
}

.formBox .row100 .inputBox {
  width: 100%;
}

.inputBox span {
  color: #603495;
  margin-top: 10px;
  margin-right: 10px;
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 1.1rem;
}

.inputBox input {
  padding: 10px;
  font-size: 1.1em;
  border: none;
  outline: none;
  border-bottom: 1px solid #603495;
}

.inputBox .error-field {
  color: red;
  min-height: 20px;
}

.inputBox textarea {
  padding: 10px;
  font-size: 1.1em;
  outline: none;
  border: 1px solid #603495;
  resize: none;
  min-height: 220px;
  margin-bottom: 10px;
}

.input-submit {
  flex-direction: row;
}
.inputBox button {
  background: #603495;
  color: #fff;
  border: 1px solid black;
  font-size: 1.4em;
  max-width: 120px;
  font-weight: 500;
  padding: 15px 0px;
  cursor: pointer;
  width: 25%;
  border-radius: 15px;
}

.inputBox button:hover {
  animation: headShake 1s;
  background: #6a4596;
}

.inputBox .form-error {
  color: red;
  font-size: 1.5rem;
  font-weight: bold;
}

.inputBox ::placeholder {
  color: #999;
}

/* info */

.contact-info {
  background: #603495;
}

.contact-info h3 {
  color: #fff;
}

.contact-info .infoBox div {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.contact-info .infoBox div span {
  min-width: 40px;
  height: 40px;
  color: #fff;
  background: #18b7ff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  border-radius: 50%;
  margin-left: 15px;
}

.contact-info .infoBox div p {
  color: #fff;
  font-size: 1.1em;
  text-align: right;
}

.contact-info .infoBox div a {
  color: #fff;
  font-size: 1.1em;
  text-decoration: none;
}

.contact-info .infoBox div a:hover {
  color: #18b7ff;
}

.sci {
  margin-top: 40px;
  display: flex;
}

.sci li {
  list-style: none;
  margin-left: 10px;
}

.sci li a {
  color: #ccc;
  font-size: 2em;
}
.sci li a:hover {
  color: #fff;
  background-color: #18b7ff;
}

.contact-map {
  padding: 0;
}

.contact-map iframe {
  width: 100%;
  height: 100%;
}

.thanks {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.thanks .contact-us-title {
  display: flex;
  justify-content: center;
  align-items: center;
}

.thanks-img-box {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 80%;
  background: #fff;
  box-shadow: 3px 3px 20px black;
  border-radius: 50%;
  overflow: hidden;
}

.thanks-img-box img {
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  object-fit: contain;
}

/* responsive */

@media screen and (max-width: 991px) {
  .thanks .contact-us-title h2 {
    font-size: 1.3rem;
  }
  .thanks-img-box {
    width: 60%;
    height: 60%;
  }
  .contact-container {
    background: #03a9f5;
  }
  .contact-us {
    padding: 20px;
  }
  .contact-box {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas: "form" "info" "map";
  }

  .formBox .row50 {
    display: flex;
    gap: 0;
    flex-direction: column;
  }

  .inputBox {
    width: 100%;
  }

  .contact-card {
    padding: 30px 10px;
  }
  .contact-map {
    min-height: 300px;
    padding: 0;
  }

  .contact-info .infoBox div span {
    font-size: 1rem;
  }

  .contact-info .infoBox div p {
    color: #fff;
    font-size: 1rem;
  }

  .inputBox button {
    font-size: 1em;
  }
}

@media screen and (max-width: 400px) {
  .contact-info {
    width: 60%;
  }
  .contact-us .contact-title h2 {
    font-size: 2rem;
  }

  .contact-us .contact-title h2 {
    font-size: 2rem;
  }

  .contact-map {
    width: 60%;
  }
  .contact-form {
    width: 60%;
  }

  .contact-form button {
    width: 35%;
    font-size: 15px;
  }

  .thanks .contact-us-title h2 {
    font-size: 1rem;
  }

  .contact-us-title h2 {
    font-size: 1.3rem;
  }
  .thanks-img-box {
    width: 40%;
    height: 40%;
  }
  .contact-container {
    background: #03a9f5;
  }
  .contact-us {
    padding: 20px;
  }
  .contact-box {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas: "form" "info" "map";
  }

  .formBox .row50 {
    display: flex;
    gap: 0;
    flex-direction: column;
  }

  .inputBox {
    width: 100%;
  }

  .contact-card {
    padding: 10px 5px;
  }
  .contact-map {
    min-height: 200px;
    padding: 0;
  }

  .sci li a {
    font-size: 2em;
    width: 30px;
    height: 30px;
  }

  .contact-info .infoBox div span {
    font-size: 1rem;
  }

  .contact-info .infoBox div p {
    color: #fff;
    font-size: 0.8rem;
  }

  .inputBox textarea {
    font-size: 15px;
  }

  .inputBox button {
    font-size: 1em;
  }
}
