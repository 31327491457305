.imageCardsContainer {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.imageCard {
  width: 33.3%;
}

.imageCard img {
  border-radius: 50px;
  width: 100px;
  height: 100px;
}

.imageCard h5 {
  margin-top: 15px;
  padding: 5px;
  font-weight: 500;
}

@media only screen and (max-width: 400px) {
  .imageCard img {
    width: 60px;
    height: 60px;
  }
  .imageCard h5 {
    font-size: 15px;
  }
}
