@media only screen and (max-width: 800px) {
  .my-container {
    width: 100%;
  }

  .about-card {
    width: 80%;
  }

  .image-notfound {
    width: 80%;
  }
  /*#main-header .logo-img {
    background-size: contain;
    margin-right: 15%;
    padding-top: 2%;
    width: 105px;
    height: 69px;
  }
  .logo {
    margin: auto;
    text-align: center;
  }*/

  .contact {
    font-size: 12px;
    padding-left: 50px;
  }
  .contact img {
    width: 30px;
    height: 30px;
  }

  .contact-us-new img {
    background-size: contain;
    width: 40px;
    height: 40px;
    margin-right: 5px;
  }

  .main-container {
    width: 100%;
  }

  .home-header {
    width: 100%;
  }

  .home-pic {
    width: 130%;
    position: relative;
    right: -70px;
  }
  .home-text {
    width: 100%;
    font-size: 25px;
    padding: 10px;
  }

  .about-text {
    font-size: 25px;
    width: 90%;
    margin: auto;
    margin-top: 15px;
    padding: 10px;
  }

  input[type="text"] {
    font-size: 24px;
  }
  .form-control {
    font-size: 24px;
  }

  .contact-us {
    width: 100%;
    font-size: 26px;
  }

  h2 {
    font-size: 40px;
  }
  .activity {
    width: 100%;
  }
  .activity-text {
    font-size: 26px;
  }
  .activity-image-container {
    text-align: center;
  }
  .activity-image {
    margin-top: 5px;
    width: 40%;
    height: 40%;
    margin-right: auto;
    margin-left: auto;
  }

  .library-container {
    height: 400px;
  }

  .student-container {
    margin: auto;
    margin-top: 20px;
  }

  .show-game-iframe {
    width: 500px;
    height: 350px;
  }

  .html-viewer-iframe {
    width: 600px;
    height: 700px;
  }
}

@media only screen and (max-width: 400px) {
  .home-pic {
    width: 130%;
    position: relative;
    right: -40px;
  }
}
