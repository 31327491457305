.library-subjects-container {
  margin: 10px;
  text-align: center;
  color: #603495;
}

.subjects-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}

.subject-card {
  width: 250px;
  height: 300px;
  margin: 10px;
  padding: 5px;
  border-radius: 15px;
  background-color: #e6e6e6;
  background-image: linear-gradient(180deg, #e6e6e6 0%, #fffff0 30%);
  box-shadow: 2px 2px 5px black;
  border: 1px solid #3ad7a6;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: baseline;
  cursor: pointer;
  color: #3ad7a6;
}

.subject-card:hover {
  box-shadow: 2px 5px 10px black;
  color: #603495;
}

.subject-card img {
  margin-top: 15px;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  box-shadow: 1px 1px 15px #ffffff;
}

.subject-card h3 {
  margin-top: 15px;
  font-size: 20px;
  cursor: pointer;
}

.subject-card p {
  font-size: 15px;

  cursor: pointer;
}
