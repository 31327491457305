.blocks-container {
  display: flex;
  justify-content: right;
  align-content: center;
  flex-wrap: wrap;
  padding: 20px 20px;
}

.block-container {
  max-width: 30%;
  border: 2px solid #22a8eb;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-content: center;

  flex-direction: column;
  margin-right: 15px;
  margin-bottom: 15px;
  padding: 10px;
  cursor: pointer;
}

.block-container:hover {
  box-shadow: 2px 2px 5px yellow;
}

.block-box-image {
  width: 100%;
}

.block-box-image img {
  max-width: 80%;
  max-height: 50px;
}

.block-content {
  width: 100%;
  color: black;
}

.containerShow {
  background-color: #f9f9f9;
  color: black;
  padding: 20px 15px;
}

.header-img {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-img img {
  max-width: 50%;
  height: 100px;
  object-fit: contain;
}

.block-title {
  text-align: center;
  font-weight: bold;
  color: black;
  text-shadow: 1px 1px 2px rgb(19, 212, 226);
}

.block-sub-title {
  border: black 2px solid;
  border-radius: 15px;
  background-color: rgb(19, 212, 226);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 20px;
  text-align: center;
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 20px;
}

.block-text-explain {
  padding: 20px;
}

.block-text-explain img {
  max-width: 80%;
  max-height: 400px;
  object-fit: contain;
}

.block-text-explain .ql-video {
  display: flex;
  justify-content: center;
  align-content: center;
  margin: auto;
  max-width: 100%;
  min-width: 60%;
  height: 400px;
}

@media screen and (max-width: 900px) {
  .block-container {
    width: 50%;
  }
  .block-content h4 {
    font-size: 1rem;
  }
}

@media screen and (max-width: 500px) {
  .blocks-container {
    justify-content: center;
  }
  .block-container {
    width: 100%;
    max-width: 80%;
  }
}
