.students-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.student-btn {
  background-color: #fefefe;
  background-image: linear-gradient(315deg, #fefefe 0%, #00a4e4 74%);
  /* background-image: linear-gradient(to top, #c471f5 0%, #fa71cd 100%); */
  padding: 10px;
  margin: 20px 10px;
  border-radius: 5px;
  box-shadow: 1px 1px 2px rgba(255, 255, 255, 0.632);
  width: 300px;
  height: 200px;
  font-size: 35px;
  color: black;
  /* text-shadow: 2px 2px orange; */
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.student-btn span {
  display: inline-flex;
  align-items: center;
  text-align: center;
  margin: auto;
}

.student-btn:hover {
  box-shadow: 3px 3px 2px rgba(255, 255, 255, 0.632);
}
