.aboutMethodContainer {
  margin-top: 20px;
  text-align: center;
  background-color: rgb(175, 235, 243, 0.5);
  border-radius: 15px;
  margin: 15px;
  background-image: linear-gradient(
    315deg,
    #ffffff 0%,
    rgb(175, 235, 243, 0.5) 100%
  );
  box-shadow: 2px 2px 5px #603495;
}

.aboutMethodSectionContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.methodSectionParag {
  width: 50%;
}
.methodSection {
  text-align: right;
  padding-right: 30px;
  font-size: 20px;
}
.methodSection span {
  font-size: 40px;
}

.methodExplain {
  text-align: right;
  padding-right: 30px;
  font-size: 18px;
}
.methodImageContainer {
  width: 50%;
}

.methodImageContainer img {
  width: 442px;
  height: 300px;
}

@media only screen and (max-width: 800px) {
  .aboutMethodSectionContainer {
    flex-direction: column;
  }
  .methodSectionParag {
    width: 100%;
    order: 2;
  }
  .methodImageContainer {
    width: 80%;
    align-self: center;
    order: 1;
  }
  .methodImageContainer img {
    width: 221px;
    height: 150px;
  }
}
