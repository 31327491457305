.blogs-container {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  padding: 20px 20px;
}

.blog-container {
  width: 30%;
  background-color: #e6e6e6;
  background-image: linear-gradient(180deg, #e6e6e6 0%, #fffff0 30%);
  box-shadow: 2px 2px 5px black;
  border-radius: 15px;
  display: flex;
  justify-content: flex-start;
  align-content: center;

  flex-direction: column;
  margin-right: 15px;
  margin-bottom: 15px;
  padding: 10px;
  cursor: pointer;
}

.blog-container:hover {
  box-shadow: 2px 2px 20px black;
}

.blog-box-image {
  width: 100%;
}

.blog-box-image img {
  max-width: 100%;
  height: 200px;
  object-fit: contain;
}

.blog-content {
  width: 100%;
  color: black;
}

.containerShowBlog {
  background-color: #f9f9f9;
  color: black;
  padding: 20px 15px;
}

.header-img {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-img-blog img {
  max-width: 50%;
  height: 100px;
  object-fit: contain;
}

.blog-title {
  text-align: center;
  font-weight: bold;
  color: black;
  text-shadow: 1px 1px 2px rgb(19, 212, 226);
}

.blog-sub-title {
  border: black 2px solid;
  border-radius: 15px;
  background-color: rgb(19, 212, 226);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 20px;
  text-align: center;
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 20px;
}

.blog-text-explain {
  padding: 20px;
}

.blog-text-explain img {
  max-width: 80%;
  max-height: 400px;
  object-fit: contain;
}

.blog-text-explain .ql-video {
  display: flex;
  justify-content: center;
  align-content: center;
  margin: auto;
  max-width: 100%;
  min-width: 60%;
  height: 400px;
}

@media screen and (max-width: 900px) {
  .blog-container {
    width: 50%;
  }
  .blog-content h4 {
    font-size: 1rem;
  }
}

@media screen and (max-width: 700px) {
  .blog-container {
    justify-content: center;
  }
  .blog-container {
    width: 100%;
    max-width: 80%;
  }
}
