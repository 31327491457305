/*general*/

@font-face {
  font-family: comix;
  src: url("../assets/comixno2clm_medium-webfont.woff");
}
@font-face {
  font-family: comix-bold;
  src: url("../assets/comixno2clm_bold-webfont.woff");
  font-weight: bold;
}

@font-face {
  font-family: secular-one;
  src: url("https://fonts.googleapis.com/css2?family=Secular+One&display=swap");
  font-weight: bold;
}

@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300&family=Secular+One&family=Varela+Round&display=swap");

* {
  font-family: "Varela Round";
}
h1,
h2,
h3 {
  font-family: "Secular One";
}
/*Varela Round Secular One*/
html {
  height: 100%;
  width: 100%;
  margin: auto 0;
  padding: 0;
}
body {
  color: white;
  font-size: 16px;
  line-height: 1;
  margin: auto 0;
  padding: 0;
  background-color: white;
  /* background-image: linear-gradient(-180deg, #603495 0%, #ffffff 90%); */
  height: 100%;
}

/* App.css */
.page-enter {
  opacity: 0;
  transform: scale(0.9);
}

.page-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}

.page-exit {
  opacity: 1;
  transform: scale(1);
}

.page-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

/*remove 6.2.22
.web-container {
  background-color:#262626;
  min-height: 100%; 
  min-width: 360px;
  min-height: 800px;
  margin: 0px;
}


.my-container {
  width: 80%;
  margin: auto;
  overflow: hidden;
  text-align: center;
}
*/
a {
  text-decoration: none;
}
a:visited {
  text-decoration: none;
}
a:link {
  text-decoration: none;
}

a:focus {
  text-decoration: none;
}

ul,
li {
  padding: 0px;
  margin: 0px;
}

/*header*/
/*remove 6.2.22
#main-header {
  color: white;
  font-weight: bold;
  font-style: oblique;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  display: grid;
  grid-template-columns: auto auto;
}

.contact {
  padding-top: 10px;
  padding-left: 100px;
}

.contact-holder {
  text-align: center;
  float: left;
}


.tel {
  border: greenyellow solid 1px;
  background: bisque;
  padding: 0 10px;
  margin-bottom: 5px;
  border-radius: 10px;
  max-width: 50px;
}

.contact img {
  background-size: contain;
  width: 50px;
  height: 50px;
  margin-right: 5px;
}
*/
/*main*/

.main-container {
  min-height: 600px;
  padding-top: 80px;
  padding-bottom: 0px;
  width: 100%;
  margin: auto;
  overflow: hidden;
  text-align: right;
  box-sizing: border-box;
}

/*footer*/

/* #main-footer {
  bottom: 0;
  background-color: white;
  width: 100%;
  text-align: center;
  padding: 20px 0;
  color: gold;
  left: 0;
  bottom: 0;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 50px;
} */

/*elements*/
.form-inline button,
input,
label {
  margin: 5px;
  padding: 5px;
}

.game-list-container {
  padding: 30px 30px;
  background-color: white;
  background-image: linear-gradient(315deg, #2d3436 0%, #000000 74%);
  border-width: 5px;
  border-style: solid;
  border-image: linear-gradient(90deg, #f70174 0%, #22a8eb 100%) 2;
  box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.6);
  margin-bottom: 10px;
}

.game-container {
  margin: 5px;
  padding: 5px;
  background-color: #0062ff;
  border: black 3px solid;
  border-radius: 10px;
  direction: rtl;
}

.modal {
  color: black;
}

.btn {
  margin-left: 5px;
}
/*.panel-container{
  border:3px rgb(241, 18, 18) solid;
  background-color: rgb(241, 18, 18);
}*/
.panel-group {
  /*background-image: url(../assets/backround_img.png);
  background-size:cover;*/
  background-color: #000000;
  border: 3px #6299ff solid;
  padding: 20px 10px;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.panel {
  margin-top: 2px;
  border: 3px #6299ff solid;
}

.home-pic {
  width: 100%;
  text-align: center;
}
.home-header {
  width: 100%;
}
.show-game-container {
  margin-top: 20px;
  text-align: center;
}

.show-game-iframe {
  width: 700px;
  height: 500px;
}

.activity {
  width: 90%;
  font-family: calibri, sans-serif;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}

.activity .media,
.activity .activity-container {
  background-color: #2d3436;
  background-image: linear-gradient(315deg, #2d3436 0%, #000000 74%);
  border-width: 5px;
  border-style: solid;
  border-image: linear-gradient(90deg, #f70174 0%, #22a8eb 100%) 2;
  box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.6);
  color: white;
  border-radius: 10px;
}

.activity-container {
  border: purple 2px solid;
  margin-bottom: 5px;
  padding: 10px;
  margin: 5px;
}

/*.activity-image-container{
  text-align: center;
  border: #0062FF 1px solid;
  
}*/

.activity-image {
  width: 64px;
  height: 64px;
  margin-bottom: 10px;
  object-fit: contain;
}
.activity-text {
  padding: 10px;
  margin: auto;
}

.modal-header {
  text-align: center;
}

.modal-title {
  padding: 5px;
  border: 5px rgb(41, 142, 186) solid;
  margin: 0 20%;
  border-radius: 25px;
  background-color: #2ecc71;
  color: whitesmoke;
  font-size: 1.2rem;
  font-weight: bold;
}

.toaster {
  color: black;
}

.rrt-confirm-holder .rrt-confirm .rrt-message {
  background-color: blanchedalmond;
}

.top-left * {
  text-align: right;
}

.web-container {
  /*background-image: url(../assets/backround_img.png);*/
  /*background-image: url(../assets/question_mark.png);*/
  /*background-image:radial-gradient( rgb(4, 134, 59) 30% , rgb(46, 204, 113) 70%);*/
  background-color: #2d3436;
  background-image: linear-gradient(315deg, #2d3436 0%, #000000 74%);

  min-height: 800px;
  position: relative;
  padding-bottom: 50px;
}

li {
  color: whitesmoke;
}

.error {
  color: red;
  font-weight: bold;
}

.thumbnail img {
  border: 1px white solid;
}

.home-text {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  margin-bottom: 15px;
  border-width: 5px;
  border-style: solid;
  border-image: linear-gradient(90deg, #f70174 0%, #22a8eb 100%) 2;
  box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.6);
  text-align: center;
  /*font-family: calibri, sans-serif;*/
  font-size: 1.2rem;
  padding: 5px;
  background-color: #2d3436;
  background-image: linear-gradient(315deg, #2d3436 0%, #000000 74%);

  border-radius: 10px;
}

.block-img-container {
  width: 50%;
}

.block-img-container img {
  height: 80px;
  width: 120px;
  object-fit: contain;
}

.area {
  width: 600px;
  height: 300px;
  background: #e5e5e5;
}
.area .content {
  width: 800px;
  height: 400px;
}

.centers-grid {
  width: 80%;
  text-align: center;
}

.centers-image {
  width: 150px;
  height: 150px;
  margin-right: auto;
  margin-left: auto;
  background-color: white;
}

.library-container {
  margin: 5px;
  padding: 5px;
  background-color: darkorange;
  border: black 3px solid;
  border-radius: 10px;
  height: 300px;
  color: #e5e5e5;
  direction: rtl;
}

.html-viewer {
  width: 100%;
  height: auto;
}
.html-viewer-container {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
  /*border: #0062FF 2px solid;*/
}
.html-viewer-iframe {
  width: 700px;
  height: 800px;
}
.library-container2 {
  text-align: center;
}
.button1 {
  display: inline-block;
  width: 231px;
  height: 184.5px;
  background-image: url("../assets/button1.png");
  background-size: cover;
  color: lightseagreen;
  /*position: relative;*/
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  font-style: oblique;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  padding: 80px 0px;
  margin: 30px;
}

.button1:hover {
  filter: drop-shadow(-8px -8px 15px yellow);
  color: rgb(28, 120, 161);
}

.replaced:hover span {
  display: none;
}

.replaced:hover::after {
  content: "בקרוב!";
  text-align: center;
  margin: auto;
}

.button3 {
  display: inline-block;
  width: 222px;
  height: 254px;
  background-image: url("../assets/button3.png");
  background-size: cover;
  color: lightseagreen;
  /*position: relative;*/
  text-align: center;
  word-wrap: break-word;
  line-height: 1;
  font-size: 2rem;
  font-weight: bold;
  font-style: oblique;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  padding: 70px 40px;
  margin: 30px;
}

.button3:hover {
  filter: drop-shadow(-8px -8px 15px yellow);
  /*font-size: 40px;*/
  color: rgb(28, 120, 161);
}

.student-container {
  display: inline-block;
  margin: 30px;
}

/*.student-container:hover {
    filter: invert(100%) drop-shadow(10px 10px 10px whitesmoke); 

  }*/

.student-thumbnail {
  background-color: #000000;
  border-radius: 10px;
  width: 200px;
  word-wrap: break-word;
}

.student-thumbnail img {
  width: 200px;
  height: 200px;
}

.student-thumbnail span {
  color: rgb(41, 142, 186);
  font-weight: bold;
  text-decoration: underline;
}

.student-thumbnail h3 {
  color: rgb(41, 142, 186);
  font-weight: bold;
}

.header {
  display: inline-block;
  text-align: center;
  background-color: #e74c3c;
  background-size: auto;
  border: 2px whitesmoke solid;
  border-radius: 25px;
  text-align: center;
}

.header-title {
  padding: 10px 30px;
  margin: auto;
  font-size: 1.2rem;
  font-weight: bold;
}

.header-container {
  text-align: right;
  margin: 10px 0px;
}

.btn-circle {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 15px;
}
.btn-circle.btn-lg {
  width: 70px;
  height: 70px;
  padding: 20px;
  font-size: 18px;
  line-height: 1.33;
  border-radius: 35px;
}

.wiki-container {
  display: flex;
  flex-wrap: wrap;
  background-color: white;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
}

.wiki-flexbox {
  background-color: bisque;
  width: 100px;
  height: 130px;
  margin: 10px;
  text-align: center;
  line-height: 75px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  padding-bottom: 30px;
  color: black;
}

.wiki-flexbox:hover {
  filter: drop-shadow(-8px -8px 15px whitesmoke);
  color: rgb(28, 120, 161);
}

.circle {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin: auto;
  margin-top: 10%;
  margin-bottom: 0px;
}

.wiki-flexbox h3 {
  margin: 0;
}

video::-internal-media-controls-overlay-cast-button {
  display: none;
}

#wa-tooltip {
  font-size: 1.2rem;
}
#wa-tooltip > .tooltip-inner {
  background-color: #2ecc71;
  padding: 5px 25px 5px 25px;
  border-radius: 50px;
}

#wa-tooltip .arrow::before {
  border-left-color: #2ecc71;
}

.about-text {
  text-align: right;
  /*font-family: impact, chicago;*/
  font-size: 1.2rem;
  padding: 15px;
  /*margin-top: 15px;
  margin-bottom: 15px;*/
  margin: 15px;
  border-radius: 10px;
  background-color: #2d3436;
  background-image: linear-gradient(315deg, #2d3436 0%, #000000 74%);
  border-width: 5px;
  border-style: solid;
  border-image: linear-gradient(90deg, #f70174 0%, #22a8eb 100%) 2;
  box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.6);
  line-height: 1.2;
}
.about-card-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.about-card {
  background-color: black;
  border-radius: 15px 15px 15px 15px;
  /*box-shadow: 2px 2px 10px white;*/
  width: 12rem;
  height: 230px;
  text-align: center;
  margin: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
}

/* .about-card:hover {
  animation: bounce 2s;
} */

.about-card .about-card-emoji {
  padding-top: 30px;
  font-size: 4rem;
  width: 100%;
  height: 50%;
  /* font-family: apple color emoji, segoe ui emoji, noto color emoji,
    android emoji, emojisymbols, emojione mozilla, twemoji mozilla,
    segoe ui symbol; */
}

.about-card .about-card-emoji img {
  width: 80%;
  height: 100%;
  object-fit: contain;
}

.about-card .card-body {
  width: 100%;
  height: 50%;
  color: white;
  padding-top: 10px;
}
.blocksTable {
  border: #2ecc71 2px solid;
  margin: auto;
  background-color: #f9f9f9;
  color: #000000;
}

.blocksTable tr td {
  border: #2ecc71 2px solid;
  margin: auto;
  padding: 10px;
}
.blocksTable td {
  padding: 5px;
}

.image-notfound {
  width: auto;
}
