.SwiperCube {
  width: 150px;
  height: 150px;
}

.SwiperCube .swiper-slide {
  background-position: center;
  background-size: cover;
}

.SwiperCube .swiper-slide .david-imgbx {
  position: absolute;
  width: 150px;
  height: 150px;
}

.SwiperCube .swiper-slide .david-imgbx img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background: black;
}
