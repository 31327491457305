.containerShow {
  background-color: #f9f9f9;
  color: black;
  width: 95%;
  margin: auto;
  padding-top: 20px;
  border-radius: 20px;
}

.containerShow img {
  max-width: 80%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
}

/*img[src$=".gif"] {
  width: 10%;
}*/

.title2 {
  text-align: center;
  font-weight: bold;
  color: black;
}

.sub-title {
  border: black 2px solid;
  border-radius: 15px;
  background-color: rgb(19, 212, 226);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 20px;
  text-align: center;
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 20px;
}

.text-explain {
  padding: 20px;
}
