.mySwiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.mySwiper .swiper-container {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.mySwiper .swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 300px;
  background: #d1ebff;
  box-shadow: 0 15px 50px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  filter: blur(4px);
}

.mySwiper .swiper-slide-active {
  transition: 0.2s;
  filter: blur(0px);
  background: #fff;
}

.testimonialBox {
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 40px;
  padding-bottom: 90px;
  color: #999;
  padding-left: 20px;
  padding-right: 20px;
}

.testimonialBox .quote {
  position: absolute;
  top: 20px;
  right: 30px;
  opacity: 0.2;
}
.testimonialBox .data-content {
  margin-top: 30px;
  font-size: 1.1rem;
}

.testimonialBox .details {
  position: absolute;
  bottom: 15px;
  right: 15px;
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.testimonialBox .details .imgBx {
  position: relative;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
}

.testimonialBox .details .imgBx img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.testimonialBox .details h3 {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1px;
  color: #2196f3;
  line-height: 1.1em;
  margin-right: 10px;
}

.testimonialBox .details h3 span {
  font-size: 12px;
  color: #666;
}

.swiper-3d .swiper-slide-shadow-right,
.swiper-3d .swiper-slide-shadow-left {
  background-image: none;
}

.mySwiperVideos {
  width: 100%;

  /* padding-top: 50px;
  padding-bottom: 50px; */
}

.mySwiperVideos .swiper-container {
  width: 50%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.mySwiperVideos .swiper-slide {
  background-position: center;
  background-size: cover;
  width: 640px;
  height: 360px;
  background: #d1ebff;
  box-shadow: 0 15px 50px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  filter: blur(4px);
}

.mySwiperVideos .swiper-slide-active {
  transition: 0.2s;
  filter: blur(0px);
  background: #fff;
}

.testimonialBoxVideo {
  position: relative;
  width: 100%;
  height: 100%;
  /* padding-top: 40px;
  padding-bottom: 90px;
  color: #999;
  padding-left: 20px;
  padding-right: 20px; */
}

.testimonialBoxVideo img {
  width: 100%;
  height: 100%;
}

.testimonialBoxVideo .video-content-slide iframe {
  width: 100%;
  height: 100%;
  z-index: 1;
}

.iframe-wrapper {
  position: relative;
}

.play-button,
.pause-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
}

.pause-button {
  color: red;
}

.modal-dialog {
  max-width: 80%;
  max-height: 800px;
}
.videoModal {
  width: 100%;
  height: 500px;
}

.videoModal iframe {
  width: 100%;
  height: 450px;
}

@media only screen and (max-width: 800px) {
  .mySwiperVideos .swiper-slide {
    width: 320px;
    height: 180px;
  }

  .modal-dialog {
    max-height: 500px;
  }
  .videoModal {
    height: 300px;
  }

  .videoModal iframe {
    height: 250px;
  }
}

@media only screen and (max-width: 350px) {
  .mySwiperVideos .swiper-slide {
    width: 240px;
    height: 135px;
  }
  .modal-dialog {
    max-height: 250px;
  }
  .videoModal {
    height: 200px;
  }

  .videoModal iframe {
    height: 150px;
  }
}
