.homeContainer {
  /* background-color: #f6ecc4;
  background-image: linear-gradient(315deg, #f6ecc4 0%, #f7d4d4 74%); */
  background-color: white;
  color: black;
  text-align: center;
}
/*video background css in the ui component*/
.courseVideoHomeContainer {
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  background-color: white;
}
/*about hadoraba section*/
.aboutHomeContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 15px;
  color: black;
}
.aboutHomeContent {
  width: 48%;
  padding: 10px;
  margin: 10px;
  background-color: #e6e6e6;
  background-image: linear-gradient(180deg, #e6e6e6 0%, #fffff0 30%);

  border-radius: 10px;
  box-shadow: 5px 5px 5px black;
}
.aboutHomeContent h2 {
  text-align: center;
}
.aboutHomeImage {
  width: 50%;
}

.theFutureImg {
  -webkit-filter: drop-shadow(5px 5px 5px #666666);
  filter: drop-shadow(5px 5px 5px rgba(11, 8, 8, 0.7));
  width: 500px;
  height: 500px;
}

.comments-container {
  background: white;
  text-align: center;
  padding: 50px 0;
  overflow: hidden;
}

.comments-container h2 {
  text-shadow: 1px 1px 2px #000;
  font-size: 3rem;
}

@media only screen and (max-width: 800px) {
  .homeContainer h1,
  h2,
  h3,
  h4 {
    padding-right: 15px;
    padding-left: 15px;
  }
  .aboutHomeContainer {
    flex-direction: column;
  }
  .aboutHomeContent {
    align-self: center;
    width: 90%;
    padding: 10px;
    order: 2;
  }

  .aboutHomeImage {
    width: 100%;
    text-align: center;
    order: 1;
  }

  .theFutureImg {
    -webkit-filter: drop-shadow(5px 5px 5px #666666);
    filter: drop-shadow(5px 5px 5px rgba(11, 8, 8, 0.7));
    width: 300px;
    height: 300px;
  }
}
