.links-container {
  display: inline-block;
  margin: auto;
  width: 100%;
}

.paragraph-links-container {
  text-align: center;
  margin: 15px;
  padding: 10px;
  border-bottom: 5px dashed tomato;
}

.links-title {
  margin-top: 15px;
  text-align: right;
  font-size: 2.5rem;
  color: #3ad7a6;
}

.paragraph-links {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.link-box {
  width: 250px;
  height: 300px;
  margin: 10px;
  border-radius: 15px;
  background-color: #0f1426;
  border: 2px solid #3ad7a6;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: baseline;
  cursor: pointer;
  position: relative;
}

.link-box:hover {
  box-shadow: 2px 5px 10px #ffffff;
  color: #3ad7a6;
}

.link-box img {
  width: 100%;
  height: 150px;
  border-radius: 15px 15px 0px 0px;
  padding: 1px;
  object-fit: cover;
}

.link-box label {
  font-size: 20px;
  color: #ffffff;
  cursor: pointer;
}

.link-box p {
  padding: 0px 5px;
  font-size: 15px;
  color: #ffffff;
  cursor: pointer;
}

.link-box button {
  position: absolute;
  bottom: 10px;
}

@media only screen and (max-width: 800px) {
  .links-title {
    text-align: center;
  }

  .paragraph-links {
    justify-content: center;
  }
}
