/* VideoBackground.css */
.image-container {
  position: relative;
  width: 100%;
  height: 90vh; /* Adjust the height as needed */
  overflow: hidden;
  top: -20px;
}

.imageBackground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.img-text-overlay {
  position: absolute;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, 0%);
  color: #fff; /* Adjust text color as needed */
  text-align: center;
  z-index: 1; /* Ensure the text appears above the video */
  background-color: rgba(0, 0, 0, 0.3);
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 15px;
}

.img-text-overlay h1 {
  font-size: 4rem; /* Adjust header text size as needed */
  margin-bottom: 10px;
  font-weight: bold; /* Make the header text bold */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6); /* Add a subtle text shadow */
}

.img-text-overlay p {
  font-size: 2rem; /* Adjust paragraph text size as needed */
  font-weight: bold; /* Make the paragraph text bold */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6); /* Add a subtle text shadow */
}

@media only screen and (max-width: 991px) {
  .image-container {
    height: 80vh; /* Adjust the height as needed */
  }
  .img-text-overlay {
    padding: 0px;
    width: 80%;
    /* bottom: 0%;
    left: 10%;
    
    transform: translateY(-50%); */
  }
  .img-text-overlay h1 {
    font-size: 3rem;
  }

  .img-text-overlay p {
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 400px) {
  .text-overlay h1 {
    font-size: 1.5rem;
  }

  .text-overlay p {
    font-size: 0.75rem;
  }

  .text-overlay p {
    font-size: 0.75rem;
  }
}
