.aboutme-container {
  background: white;
  padding: 50px;
}

.aboutme-card {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e6e6e6;
  background-image: linear-gradient(180deg, #e6e6e6 0%, #fffff0 30%);
  box-shadow: 10px 10px 50px rgba(0, 0, 0, 0.2);
}
.aboutme-info {
  width: 70%;
  padding: 30px;
}

.aboutme-info h1 {
  color: #000;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  font-size: 2rem;
  font-family: comix-bold;
}

.aboutme-info p {
  font-size: 1.2rem;
  line-height: 1.2em;
  color: #000;
  font-weight: 500;
  margin-bottom: 0.8em;
}

.cube-container {
  width: 30%;
  height: 300px;
  position: relative;
}

@media screen and (max-width: 991px) {
  .aboutme-container {
    padding: 20px 10px;
  }
  .aboutme-card {
    display: flex;
    flex-direction: column-reverse;
    flex-wrap: wrap;
  }

  .aboutme-info {
    width: 100%;
  }

  .aboutme-info h1 {
    font-size: 1.5rem;
  }

  .aboutme-info p {
    font-size: 1rem;
  }

  .cube-container {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 0;
    height: auto;
  }
}
