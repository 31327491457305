footer {
  position: relative;
  width: 100%;
  height: auto;
  padding: 50px 100px;
  background-color: #afebf3;
  background-image: linear-gradient(180deg, #afebf3 0%, #fff9c4 100%);
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  font-weight: 500;
}

footer .container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row;
}

footer .container .sec {
  margin-left: 30px;
}

.footer-title {
  display: flex;
}

.footer-title img {
  /* margin-right: 10px; */
  width: 90px;
  height: 40px;
}

footer .container .sec.aboutus {
  width: 40%;
}

footer .container h2 {
  position: relative;
  color: black;
  font-weight: 500;
  margin-bottom: 15px;
}
footer .container h2:before {
  content: "";
  position: absolute;
  bottom: -5px;
  right: 0;
  width: 50px;
  height: 2px;
  background: #603495;
}

footer p {
  color: #000000;
}

.sci {
  margin-top: 20px;
  display: flex;
}

.sci li {
  list-style: none;
}

.sci li a {
  display: inline-block;
  width: 40px;
  height: 40px;
  background: #222;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0px;
  text-decoration: none;
}
.sci li a:hover {
  background: #603495;
}

.sci li a .fab {
  color: #fff;
  font-size: 20px;
}

.sci li a .icon {
  color: #fff;
  width: 20px;
}

.quickLinks {
  position: relative;
  width: 25%;
}

.quickLinks ul li {
  list-style: none;
}

.quickLinks ul li a {
  color: #000000;
  text-decoration: none;
  margin-bottom: 10px;
  display: inline-block;
}

.quickLinks ul li a:hover {
  color: #603495;
}

.info {
  color: black;
}

.contact {
  width: calc(35% - 60px);
  margin-left: 0 !important ;
}

.contact .info {
  position: relative;
}
.contact .info li {
  display: flex;
  margin-bottom: 16px;
}

.contact .info li span:nth-child(1) {
  color: black;
  font-size: 20px;
  margin-left: 10px;
}

.contact .info li span {
  color: #000000;
}
.contact .info li a {
  color: #000000;
  text-decoration: none;
}

.contact .info li a:hover {
  color: #603495;
}

.copyrightText {
  width: 100%;
  padding: 8px 100px;
  text-align: center;
  color: #000000;
}

@media only screen and (max-width: 800px) {
  footer {
    padding: 40px;
  }

  footer .container {
    flex-direction: column;
  }
  footer .container .sec {
    margin-left: 0;
    margin-bottom: 40px;
  }

  footer .container .sec.aboutus,
  .quickLinks,
  .contact {
    width: 100%;
  }
  .copyrightText {
    padding: 8px 40px;
  }
}
