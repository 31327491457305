.animation-container-scrollAnimation {
  position: relative;
}

.content-scrollAnimation {
  opacity: 0;
  transition: opacity 0.8s ease; /* Add transition for opacity property */
}

.animate-opacity-in-scrollAnimation {
  opacity: 1;
}
